<template>
  <div class="mt-2" style="background-color: white; padding: 10px">
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-md-6">
          <h5>Plot Assignemnt</h5>
          <div class="form-group">
            <label for="">Project</label>
            <select
              class="form-control"
              v-model="form._project_id"
              @change="projectChanges"
            >
              <option value="">Select Project</option>
              <option
                :value="project._id"
                v-for="(project, index) in projects"
                :key="index"
              >
                {{ project.name }} ({{ project.code }})
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Zone</label>
            <select
              class="form-control"
              v-model="form._zone_id"
              @change="zoneChanges"
            >
              <option value="">Select Zone</option>
              <option
                :value="zone._id"
                v-for="(zone, index) in zones"
                :key="index"
              >
                {{ zone.code }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Plots</label>
            <select
              class="form-control"
              v-model="form._plot_id"
              @change="plotChange"
            >
              <option value="">Select Plots</option>
              <option
                :value="plot._id"
                v-for="(plot, index) in plots"
                :key="index"
              >
                {{ plot.code }} {{ plot.area }} Acre
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="">Credits</label>
            <input
              type="text"
              class="form-control"
              v-model="form.credits"
              readonly
            />
          </div>
          <div class="form-group">
            <button class="btn btn-lg btn-success">Save</button>
          </div>
        </div>
        <div class="col-md-6">
          <table class="table table-sm table-bordered text-center">
            <thead>
              <tr>
                <th>Acre</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="credit in credits" :key="credit">
                 <td>{{ credit.acre }}</td>
                <td>{{ credit.points }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      projects: [],
      zones: [],
      plots: [],
      assets: [],
      credits: [],
      form: {
        _user_id: "",
        _project_id: "",
        _zone_id: "",
        _plot_id: "",
        assets: [],
        credits: 0,
      },
    };
  },
  mounted() {
    this.getProjects();
  },
  methods: {
    getProjects() {
      window.axios.get("project").then((resp) => {
        console.log(resp.data);
        this.projects = resp.data.data;
      });
    },
    projectChanges() {
      window.axios
        .get("/project-zones/" + this.form._project_id)
        .then((resp) => {
          console.log(resp);
          this.zones = resp.data.data;
        });
      // get the project resort details
      window.axios
        .get("/project-resort-details/" + this.form._project_id)
        .then((resp) => {
          console.log(resp);
          this.credits = resp.data.data.credits;
        });
    },
    zoneChanges() {
      window.axios
        .get("/all-plots-unassigned/" + this.form._zone_id)
        .then((resp) => {
          this.plots = resp.data.data;
        });
    },
    plotChange() {
      this.plots.filter((plot) => {
        if (plot._id == this.form._plot_id) {
          this.form.credits = this.calculateCreditAccordingToArea(plot.area);
          return plot;
        }
      });
    },
    calculateCreditAccordingToArea(area) {
      console.log(area);
      let selectedPlotAreaCrdit = 0;
      if (0.25 <= area && area < 0.5) {
        selectedPlotAreaCrdit = this.credits.filter((credit) => {
          return credit.acre == 0.25 ? credit : "";
        });
        selectedPlotAreaCrdit = selectedPlotAreaCrdit[0].points;
      }

      if (0.5 <= area && area < 1) {
        selectedPlotAreaCrdit = this.credits.filter((credit) => {
          return credit.acre == 0.5 ? credit : "";
        });
        console.log(selectedPlotAreaCrdit);
        selectedPlotAreaCrdit = selectedPlotAreaCrdit[0].points;
      }
      if (0.75 <= area && area < 1) {
        selectedPlotAreaCrdit = this.credits.filter((credit) => {
          return credit.acre == 0.75 ? credit : "";
        });
        selectedPlotAreaCrdit = selectedPlotAreaCrdit[0].points;
      }

      if (1 <= area && area < 100) {
        selectedPlotAreaCrdit = this.credits.filter((credit) => {
          return credit.acre == 1 ? credit : "";
        });
        console.log(selectedPlotAreaCrdit[0].points);
        selectedPlotAreaCrdit = selectedPlotAreaCrdit[0].points * area;
      }
      return selectedPlotAreaCrdit;
    },
    submitForm() {
      let user_id = this.$route.params.id;
      this.form._user_id = user_id;
      window.axios.post("/customer-plot", this.form).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          alert("successfully Saved");
          this.$router.push("/users/" + user_id);
        }
      });
    },
  },
};
</script>